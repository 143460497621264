.form {
  margin-top: 20px;
}

.inputs div {
  display: flex;
  height: 100%;
  flex-flow: column;
  justify-content: space-between;
  margin-bottom: 4%;
}

.inputs input {
  height: 16px;
  font-size: 14px;
}

.inputs div label {
  font-size: 16px;
  margin-bottom: 5px;
}

.inputs button {
  background-color: #b63075;
  border: none;
  width: 130px;
  height: 25px;
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.inputs button:hover {
  background-color: white;
  border: 3px solid #b63075;
  color: #b63075;
  transition: ease-in 100ms;
}

.error {
  color: #b63075;
  font-weight: bold;
}
