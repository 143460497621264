.background {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(234, 234, 241);
  height: 100%;
  width: 100%;
  z-index: -9;
}

.loginWindow {
  position: absolute;
  left: 50%;
  top: 40%;
  background-color: rgb(255, 255, 255);
  width: 400px;
  height: 265px;
  max-width: 90vw;
  transform: translate(-50%, -50%);
  border-radius: 7px;
  z-index: -8;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.form {
  width: 80%;
  height: 200px;
  margin-top: 20px;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
}

.form input {
  height: 35px;
  margin-top: 10px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding-left: 5px;
  font-size: 14px;
}

.submitBtn {
  height: 30px;
  margin-top: 10px;
  border: none;
  border-radius: 2px;
  background-color: #972a62;
  color: white;
  font-weight: bold;
}

.loginLogo {
  width: 200px;
  margin: auto;
}

.error {
  color: #972a62;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 10%;
}
