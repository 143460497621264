.mainContainer {
  width: 90%;
  margin-left: 5%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.headerCounters {
  display: flex;
  justify-content: space-between;
}

.countTotal {
}

.countSpecial {
  margin-left: 30px;
}

.userContainer {
  margin-bottom: 30px;
}

.backArrow {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 80px;
  margin-left: 10px;
  cursor: pointer;
}

.backArrow:hover {
  color: #b63075;
  transition: ease-in 100ms;
}

@media only screen and (max-width: 700px) {
  h2 {
    font-size: 18px;
  }

  .countSpecial {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .backArrow {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 500px) {
  h2 {
    font-size: 18px;
    text-align: center;
  }

  .countSpecial {
    margin-left: 0;
  }
} ;
